.blogContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .blogCard {
    text-align: left;
    width: calc(70% - 10px);
    margin-bottom: 20px;
    font-family: aileron;
  }
  .blogCard:hover {
    transform: scale(1.05); 
  }
  .title{
    font-size: 50px;
    font-family: "Desire-Pro";
  }
  .title:hover{
    color:#dcb826;
  }
  .dateContainer {
    display: flex;
    align-items: left;
    margin-bottom: 8px;
  }

  
  .calendarIcon {
    margin-right: 5px;
  }
  .smallIcon {
    margin-top: 0.6em;
    font-size: 0.5em; 
    margin-bottom: -0.5em;
  }
  
  .smallText {
    font-size: 0.8em; 
    margin-bottom: 0.1em;
  }
  .fullBlog{
    display: "flex";
    justify-content: flex-start;
    text-align: left;
    margin-top: "20px";
    width: "100%";
    font-family: aileron;
  }
  .readButton{
    display: flex;
    justify-content: flex-end;
    padding-right:1em;
  }
  .textStyle{
    font-size: 1.2em;
    font-weight: 400;
  }
  .ThemeOrange {
    background: #1d3118;
    color: #ebe3d1;
    border: 2px solid black;
    padding:1em
  }
  
  .ThemeOrange:hover {
    background: #fff;
    color: #1d3118;
  }

  @media (max-width: 992px) {
    .blogCard {
      width: calc(50% - 10px);
    }
  }
  
  @media (max-width: 576px) {
    .blogCard {
      width: 100%; 
    }
  }