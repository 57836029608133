.bannerContainer {
  background-color: black;
  border-radius: 10px;
  padding: 3em;
}
.header1 {
  color: #f3a537;

  font-family: Desire-Pro;
  font-size: 4em;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
}
.header2 {
  color: #fff;
  font-family: Desire-Pro;
  font-size: 4em;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
}
.subHeader1 {
  color: #fff;
  font-family: Aileron;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
}
.col1 {
  /* margin-left: 1em; */
}
.bannerImage {
  height: 390px;
}
