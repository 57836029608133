.tokenContainer {
  display: flex;
  min-height: 12.1875rem;
  padding: 2.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  border-radius: 1.25rem;
  background: #1e291c;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.investmentHeadingContainer {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  flex: 1 0 0;
}

.investmentHeading {
  color: #fff;
  font-family: Aileron;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  opacity: 0.6;
}

.orderEllipse {
  width: 1.0625rem;
  /* fill: #F3A537; */
}

.tokenBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.countText {
  color: #fff;
  font-family: Aileron;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  width: 45% !important;
}

.buyBtn {
  width: 50% !important;
}

.buymoreBtn {
  height: 3.125rem;
  flex-shrink: 0;
  width: 10rem;
}

.btnText {
  color: #212121;
  width: 10rem;
  text-align: center;
  font-family: Aileron;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (max-width: 767px) {
  .tokenContainer {
    min-height: 10.1875rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    padding: 1rem;
    margin: auto;
    margin-left: 20px;
    margin-right: 20px;
  }
  .buymoreBtn {
    height: 3.125rem;
    flex-shrink: 0;
    width: 7rem;
  }
  .countText {
    color: #fff;
    font-family: Aileron;
    font-size: 3.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
  }
}

@media screen and (max-width: 1500px) {
  .countText {
    font-size: 2.3rem;
  }

  .buymoreBtn {
    height: 40px;
    width: 109px;
  }
}
