.modalBody {
  border-radius: 20px !important;
  padding: 40px 40px 60px 40px !important;
}
.errorValidation {
  color: red;
  font-weight: bold;
}
@media (max-width: 426px) {
  .modalBody {
    padding: 40px 20px !important;
  }
}
