.buymoreBtn {
  height: 3.125rem;
  flex-shrink: 0;
  width: 100%;
}

.btnText {
  color: #212121;
  text-align: center;
  font-family: Aileron;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.faqDiv {
  max-height: 580px;
  overflow-y: scroll;
}
@media screen and (max-width: 767px) {
  .buymoreBtn {
    display: none;
  }
  .faqDiv {
    max-height: 380px;
    overflow-y: scroll;
  }
}
