.bannerContainer {
  background-color: #ebe3d1;
  border-radius: 10px;
  padding: 3em;
  display: flex;
  justify-content: space-between;
}
.header1 {
  color: #212121;

  font-family: Desire-Pro;
  font-size: 5em;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -2px; */
}
.header2 {
  color: #212121;
  font-family: Aileron;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 3em !important;
}
.col1 {
  text-align: left;
}
.bannerImage {
  height: 300px;
}
@media screen and (max-width: 426px) {
  .bannerContainer {
    background-color: transparent;
  }
  .header1 {
    font-size: 55px;
  }
  .header2 {
    margin-bottom: 3em !important;
    font-size: 17px;
  }
}
