.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.labelContainer {
  display: flex;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #000000d9;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3px;
}
.label {
  color: #212121;
  margin-bottom: 0.5em;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.inputBox {
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.4);

  background: #fff;
  padding: 5px 12px;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  &::placeholder {
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #00000040;
  }

  &:focus {
    border: 1px solid #212121;
    outline: 1px solid #212121;
  }
}

.inputFieldContainer {
  width: 100%;
  position: relative;
  display: flex;
}

.toggleButton {
  border: transparent;
  text-decoration: underline;
  background-color: transparent;
  margin-left: -4rem;
  color: #212121;

  /* text-align: right; */
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
