.DesktopFooterContainer {
    display: flex;
    gap: 60px;
    justify-content: center;
    background: #1D3118;
    padding: 60px 100px;
    flex-wrap: wrap;
}

.FooterContent {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: stretch;
}

.FooterContent:first-child {
    width: 15%;
    justify-content: space-between;
}

.FooterLogoArea {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: start;
}

.MoveFooterLogoArea {
    display: none;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: start;
}

.FooterLink {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}

.FooterLink:hover {
    color: #FFFFAA;
}

.FooterCopyright {
    color: #FFF;
    font-family: Aileron;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
}

@media screen and (max-width: 1600px) {
    .DesktopFooterContainer {
        padding: 35px;
    }

}

@media screen and (max-width: 1200px) {
    .DesktopFooterContainer {
        padding: 40px 70px;
    }
}

@media screen and (max-width: 996px) {
    .FooterContent:first-child {
        width: 100%;
    }

    .FooterContent {
        justify-content: flex-start;
    }

    .FooterLogoArea {
        display: none;
    }

    .MoveFooterLogoArea {
        display: flex;
    }

    .DesktopFooterContainer {
        padding: 40px 70px;
        justify-content: left;
        flex-direction: column;
    }

    .BottomMobileContent {
        display: flex;
        gap: 35px;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 80px;
    }

    .MobileFooterMenu {
        color: #EBE3D1;
        font-family: Aileron;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .MobileFooterMenu:hover {
        color: #EBE37F;
    }
}

@media screen and (max-width: 767px) {
    .DesktopFooterContainer {
        padding: 40px;
        justify-content: left;
    }
}