.MainDiv {
  width: 500px;
  /* height: 500px; */

  margin: 0 auto;
  text-align: left;
  margin-top: 10px;
  /* background-color: red; */
}

.Heading {
  width: 500px;
  height: 28px;
  flex-shrink: 0;
  color: #212121;
  font-family: Desire-Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;

  margin-bottom: 34px;
  /* 80% */
}
.FormForgotPassword {
  width: 295px;
  height: 26px;
  flex-shrink: 0;
  color: #212121;
  font-family: Aileron;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;

  margin-top: 10px;
}
.Paragraph {
  width: 500px;
  height: 26px;
  flex-shrink: 0;
  color: #212121;
  font-family: Aileron;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-bottom: 37px;
}

.Form {
  text-align: left;
}

.InputField {
  margin-top: 24px;
}

.CancelButton {
  width: 241.935px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #1d3118;
  margin-top: 40px;
}

.CancelDiv {
  text-align: center;
  margin-top: 20px;
}

.ButtonTextReset {
  width: 229.032px;
  height: 20px;
  flex-shrink: 0;
  color: #212121;
  text-align: center;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ButtonTextCancel {
  height: 18px;
  align-self: stretch;
  color: #212121;
  text-align: center;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.InputComponent {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #212121;
  background: #fff;
  text-align: center;
  margin-right: 25px;
}

.labelContainer {
  display: flex;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #000000d9;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3px;
}
.label {
  color: #212121;
  margin-bottom: 0.5em;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.MobileMainDiv {
  margin: 32px 20px 32px 20px;
}

.MobileHeading {
  /* width: 335px; */
  height: 32px;
  flex-shrink: 0;
  font-family: Desire-Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  margin-bottom: 34px;
  color: #212121;
}

.MobileParagraph {
  color: #212121;
  font-family: Aileron;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* width: 500px; */
  /* height: 26px; */
  flex-shrink: 0;
  text-align: left;
  margin-bottom: 32px;
}

.MobilelabelContainer {
  color: #212121;
  font-family: Aileron;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 32px;
  margin-bottom: 32px;
}

.MobileInputComponent {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #212121;
  background: #fff;
  text-align: center;
}
