.bannerContainer {
  background: #1d3118;
  border-radius: 10px;
  padding: 3em;
}
.header1 {
  color: #ebe3d1;
  font-family: Desire-Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -2px; */
  display: flex;
  justify-content: center;
  margin-bottom: 0.7em !important;
}
.header2 {
  color: #ebe3d1;
  font-family: Desire-Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  display: flex;
  justify-content: center;
  margin-bottom: 0.7em !important;
  margin-top: 0.7em !important;
}
.cardContainer {
  padding: 0px 6vw !important;
}
.card {
  margin-top: 1em;
  border-radius: 15px !important;
  background-color: #344630 !important;
  color: #ebe3d1 !important;
  text-align: left;
}
.cardBody {
  padding: 2em;
}
.advisoryImage {
  /* height: 18vw; */
}
.announcementSoonHeader {
  display: flex;
  justify-content: center;
}
