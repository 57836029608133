.icon {
  color: rgba(29, 49, 24, 0.2);
  text-align: center;
  font-family: Aileron;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.hover {
  color: #1d3118;
  text-align: center;
  font-family: Aileron;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.hover img {
  height: 16px;
  margin: 1em 0em 0.5em 0em;
}
.icon img {
  height: 16px;
  margin: 1em 0em 0.5em 0em;
}
