.MainDiv {
    width: 500px;
    margin: 0 auto;

    margin-top: 10px;
  }
  
  .Heading {
    width: 500px;
    height: 28px;
    flex-shrink: 0;
    color: #212121;
    font-family: Desire-Pro;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    margin-bottom: 34px;
    /* 80% */
  }
  
  .Paragraph {
    width: 500px;
    height: 26px;
    flex-shrink: 0;
    color: #212121;
    font-family: Aileron;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin-bottom: 40px;
  }
  
  .Form {
    text-align: left;
  }
  
  
  .FormSubmitButtonText {
    width: 500px;
    height: 20px;
    flex-shrink: 0;
    color: #212121;
    text-align: center;
    font-family: Aileron;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .FormBackToLogin {
    width: 500px;
    height: 18px;
    flex-shrink: 0;
    color: #212121;
    font-family: Aileron;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  
    margin-top: 30px;
  }

  .MobileMainDiv {
   margin: 32px 20px 32px 20px; 
  }

  .MobileHeading {
    /* width: 335px; */
    height: 32px;
    flex-shrink: 0;
    font-family: Desire-Pro;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    margin-bottom: 34px;
    color: #212121;
  }

.MobileParagraph {
  color: #212121;
  font-family: Aileron;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* width: 500px; */
  /* height: 26px; */
  flex-shrink: 0;
  text-align: left;
  margin-bottom: 32px;
}

.MobileFormBackToLogin {
  /* width: 500px; */
  text-align: center;
  height: 18px;
  flex-shrink: 0;
  color: #212121;
  font-family: Aileron;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-top: 24px;
  font-size: 16px;
}