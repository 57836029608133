.orderContainer {
    /* display: flex; */
    padding: 2.5rem 2.5rem 0rem 2.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.75rem;
    border-radius: 1.25rem;
    background: #FFF;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.15);
    height: 36.5rem;
    overflow-y: scroll;
}
.orderContainer::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
  }

.orderBox {
    padding: 1.25rem 2rem;
    align-items: flex-start;
    align-content: flex-start;
    gap: 1.4375rem 2rem;
    border-radius: 0.625rem;
    background: #EBE3D1;
    margin-top: 1rem;
    min-height: 10rem;
}

.orderHeadingContainer {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    flex: 1 0 0;
}

.orderEllipse {
    width: 1.0625rem;
    /* fill: #F3A537; */
}

.orderHeading {
    color: #F4A537;
    font-family: Aileron;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.orderBoxTitle {
    color: rgba(33, 33, 33, 0.40);
    font-family: Aileron;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.oderBoxValue {
    color: #212121;
    font-family: Aileron;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}

.orderItem {
    display: flex;
    width: 6.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    flex-shrink: 0;
}

@media screen and (max-width: 767px) {
    .orderContainer {
        padding: 1.5rem 0rem 1.5rem 0rem;
        gap: 1.25rem;
        border-radius: 0rem;
    
    }
    .orderHeadingContainer{
        margin-bottom: 1.5rem;
        padding-left: 1rem;
    }
    .orderBox{
        background-color: rgba(243, 243, 243, 0.801);
    }
  }