.sliderContainer {
  height: 11.9375rem;
}
.imgContainer {
  margin-top: -5rem;
  margin-left: -4rem;
  z-index: 22;
}

@media screen and (max-width: 767px) {
  .imgContainer {
    margin-top: 1rem;
    margin-left: 0rem;

    z-index: 22;
    width: 300px;
  }
  .sliderContainer {
    text-align: center;
  }
}
