.orderContainer {
  background: #1d3118;
  border-top: 1px solid #50644b;
  /* height: 100vh; */
}

.body {
  padding: 5rem 10.625rem 0rem 10.625rem;
  gap: 7.5rem;
  display: block;
  background-color: #1d3118;

}

.header {
  color: #fff;
  font-family: Desire-Pro;
  font-size: 6.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 5rem;
  /* 80% */
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
}

.subtitle {
  color: #fff;
  text-align: center;
  font-family: Aileron;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.subtitleContainer {
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
}

.imgContainer {
  align-items: center;
  display: flex;
  padding: 5rem 10.625rem 0rem 10.625rem;
}

.img {
  width: 15rem;
  height: 15rem;
  flex-shrink: 0;
}

.orderTabContainer {
  display: flex;
  padding: 1.25rem 2rem;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.8rem 2rem;
  flex-wrap: wrap;
  border-radius: 1.25rem;
  background: #1e291c;
  margin-bottom: 1rem;
}

.imgLeft{
  display: block;
}

@media screen and (max-width: 767px) {
  .body {
    padding: 5rem 1.5rem 1rem;
    gap: 0rem;
  }

  .header {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 0.575rem;

  }
  .imgContianer{
    height: 220px;
    width: 220px;
  }
  .imgLeft{
    display: none;
  }
}