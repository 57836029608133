.orderTabContainer {
    display: flex;
    padding: 1.25rem 2rem;
    align-items: flex-start; 
    align-content: flex-start;
    gap: 0.8rem 2rem;
    flex-wrap: wrap; 
    border-radius: 1.25rem;
    background: #1E291C;
    margin-bottom: 1rem;
}

.orderDiv {
    display: flex;
    width: 6.8rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    flex-shrink: 0;
}

.linkText{
    color: #fff;
    padding-top: 0.5rem;
    font-family: Aileron;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}
.orderDivLast {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
}

.orderDivHeading {
    color: rgba(255, 255, 255, 0.40);
    font-family: Aileron;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}

.orderDivText {
    color: #FFF;
    padding-top: 0.5rem;
    font-family: Aileron;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}