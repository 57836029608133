.changePassword {
  color: #212121;
  text-align: right;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  margin-top: 20px;
}
.OuterDiv {
  padding: 60px 40px;
}
.Form {
  text-align: left;
}
.heading1 {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 80% */

  height: 32px;
}
.heading2 {
  align-self: stretch;

  color: #212121;
  font-family: Aileron;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 32px;
  margin-bottom: 20px;
}
.fields {
  margin-top: 24px;
  margin-bottom: 24px;
}
.forgotPassword {
  margin-top: 20px;

  color: #212121;
  font-family: Aileron;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.resetButton {
  margin-top: 40px;
}
.modalContent {
  width: 580px !important;
}
.FormJoinNowLink {
  border: 0;
  background: white;
}
.cancelButton {
  border: 0;
  background: white;
  color: #212121;
  text-align: center;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.CancelDiv {
  text-align: center;
  margin-top: 20px;
}


.MobileMainDiv {
  margin: 32px 20px 32px 20px; 
 }

 .MobileHeading {
   /* width: 335px; */
   height: 32px;
   flex-shrink: 0;
   font-family: Desire-Pro;
   font-size: 40px;
   font-style: normal;
   font-weight: 400;
   line-height: 32px;
   text-align: left;
   margin-bottom: 34px;
   color: #212121;
 }

.MobileParagraph {
 color: #212121;
 font-family: Aileron;
 font-size: 17px;
 font-style: normal;
 font-weight: 400;
 line-height: normal;
 /* width: 500px; */
 /* height: 26px; */
 flex-shrink: 0;
 text-align: left;
 margin-bottom: 32px;
}

.FormButtonSpan {
  width: 500px;
  height: 20px;
  flex-shrink: 0;
  color: #212121;
  text-align: center;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}