.bannerContainer {
  background: #1d3118;
  border-radius: 10px;
  padding: 3em;
}
.header1 {
  color: #ebe3d1;
  font-family: Desire-Pro;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -2px; */
  display: flex;
  justify-content: center;
  margin-bottom: 0.7em !important;
}
.cardContainer {
  /* padding: 0px 6em !important; */
}
.card {
  border-radius: 15px !important;
  background-color: #344630 !important;
  color: #ebe3d1 !important;
  text-align: left;
  /* height: 100; */
}
.cardBody {
  padding: 3.5em 2em !important;
}
.cardTitle {
  font-size: 2vw !important;
}
.cardText {
  font-size: 1vw !important;
}

.cardImage {
  height: 20vw;
  margin-top: 2em;
  width: 100%;
}
.imageMargin {
  width: 100%;
  display: flex;
  margin: auto;
}

.leftSection,
.rightRight {
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .row {
    display: flex;
    align-items: stretch;
  }
}

@media screen and (max-width: 767px) {
  .bannerContainer {
    background-color: #fff;
  }
  .header1 {
    color: #1d3118;
  }
  .cardContainer {
    padding: 0px !important;
    gap: 1rem;
  }
  .imageMargin {
    width: 40%;
  }
  .cardBody {
    padding: 1.5 !important;
  }
  .cardTitle {
    font-size: 4vw !important;
  }
  .cardText {
    font-size: 3vw !important;
  }
  .tag {
    margin-left: 1vw;
  }
}

@media screen and (max-width: 426px) {
  .bannerContainer {
    padding: 1em 2em;
    text-align: left;
  }
  .header1 {
    justify-content: left;
    font-size: 40px;
  }

  .cardImage {
    margin: 2rem auto;
    width: 160px;
    height: 152.038px;
  }
  .cardBody {
    padding: 2rem !important;
  }
}
