.bannerContainer {
  padding: 10em 0em 3em 0em;
}
.header1 {
  color: #212121;

  font-family: Desire-Pro;
  font-size: 4em;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -2px;
}
.header2 {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 2em !important;
}
.col1 {
  text-align: left;
}
.bannerImage {
  height: 300px;
}
.img1 {
  margin-top: -5em;
  width: 100%;
  height: 100%;
}
.banner1 {
  background-color: #ebe3d1;
  border-radius: 10px;
  height: 25vw;
  margin: 10px 0em;
}
.banner2 {
  background-color: #1c2a4d;
  border-radius: 10px;
  color: #fff;
  text-align: left;
  /* font-family: Aileron; */
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1em;
  height: 25vw;
  margin: 10px 0em;
}

.banner3 {
  background-image: url("../../../Assets/Images/about-banner-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  color: #fff;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 2em;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  height: 25vw;
  margin: 10px 0em;
  vertical-align: middle;
}
.bigFont {
  font-size: 2.5em;
}

.banner4 {
  background-color: #ebe3d1;
  border-radius: 10px;
  color: #212121;
  text-align: left;
  /* font-family: Aileron; */
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1em;
  height: 25vw;
  margin: 10px 0em;
}

.banner5 {
  background-image: url("../../../Assets/Images/image-texture-2 1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  color: #fff;
  font-size: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 1em;
  display: flex;
  text-align: center;
  align-items: center;
  height: 25vw;
  margin: 10px 0em;
}
.banner6 {
  /* background-color: #1c2a4d; */
  border-radius: 10px;
  color: #fff;
  text-align: left;
  /* font-family: Aileron; */
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  /* padding: 1em; */
  height: 25vw;
  margin: 10px 0em;
}
.banner7 {
  background-color: #1c2a4d;
  background-image: url("../../../Assets/Images/about-banner-7.svg");
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: center;
  border-radius: 10px;
  height: 25vw;
  margin: 10px 0em;
  padding: 2em;
}
@media screen and (max-width: 768px) {
  .banner2,
  .banner3,
  .banner4,
  .banner5 {
    font-size: 2.2vw;
  }
}
@media screen and (max-width: 426px) {
  .banner1 {
    height: 100%;
    border-radius: 0px;
  }
  .banner2 {
    background-color: #ebe3d1;
    border-radius: 0px;
    color: #212121;
    height: 100%;
    padding-bottom: 1rem;
    font-size: 4vw;
  }
  .banner3 {
    font-size: 5.5vw;
    margin: 2em 1em;
    height: 70vw;
  }
  .backgroundColor {
    background-color: #ebe3d1;
  }
  .banner4 {
    background-color: transparent;
    height: 100%;
    font-size: 4.5vw;
  }
  .banner5 {
    font-size: 5.5vw;
    margin: 2em 1em;
    height: 70vw;
  }
  .banner6 {
    height: 70vw;
  }
  .bigFont {
    font-size: 13.5vw;
  }
}
