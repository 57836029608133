.greetingContainer {
  width: 27.75rem;
  height: 3rem;
}

.welcomeText {
  color: #fff;
  font-family: Desire-Pro;
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3rem;
  /* 96% */
}

.nameText {
  color: #f4a537;
  font-family: Desire-Pro;
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3rem;
  margin-left: 0.7rem;
}

@media screen and (max-width: 767px) {
  .greetingContainer {
   padding-left: 10px;
   padding-top: 1rem;
   padding-bottom: 1rem;
   margin-bottom: 2.5rem;
  }
}