.bannerContainer {
  background-color: #1d3118;
  border-radius: 20px;
  /* margin: 0.5em; */
  /* height: 20rem; */
}
.header1 {
  color: #f4a537;
  font-family: Desire-Pro;
  font-size: 5em;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  text-align: left;
  /* letter-spacing: -2px; */
}
.header2 {
  color: #fff;
  font-family: Aileron;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  /* align-items: flex-start; */
}
.col1 {
  text-align: left;
  padding: 3em !important;
}
.bannerImage {
  /* height: 30rem; */
  width: 100%;
  border-radius: 20px;
}
.col2 {
  padding: 0px;
  text-align: right;
}

@media screen and (max-width: 767px) {
  .bannerContainer {
    background-color: #fff;
  }

  .header1 {
    color: #000000;
    font-family: Desire-Pro;
    font-size: 2.5rem;
  }

  .header2 {
    color: #000000;
    font-family: Aileron;
    font-size: .8rem;
  }
  .bannerImage {
    /* height: 30rem; */
    width: 100%;
    border-radius: 20px;
  }
}
