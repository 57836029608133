.MainDiv {
  width: 500px;
  margin: 0 auto;
  margin-top: 10px;
}

.HeadingComponent {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  width: 500px;
  height: 28px;
  flex-shrink: 0;
  text-align: left;
  /* 80% */
}

.ParagraphComponent {
  width: 500px;
  height: 26px;
  flex-shrink: 0;
  color: #212121;
  font-family: Aileron;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: left;
}

.FormComponent {
  text-align: left;
}

/* .ValidateOtpDivComponent {
      width: 500px;
      height: 50px;
      flex-shrink: 0;
      border-radius: 10px;
      background: #F4A537;
      margin-top: 50px;
      margin-bottom: 30px;
  } */

.ValidateOtpButtonText {
  width: 474.194px;
  height: 20px;
  flex-shrink: 0;
  color: #212121;
  text-align: center;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ResendOtpComponent {
  width: 500px;
  height: 18px;
  flex-shrink: 0;
  color: #212121;
  text-align: center;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.InputComponent {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #212121;
  background: #fff;
  text-align: center;
}

#ResendOtp {
  text-align: center;
}

@media screen and (max-width: 425px) {
  .MainDiv {
    width: unset !important;
    margin-left: 20px;
    margin-right: 20px;
  }

  .HeadingComponent {
    color: #212121;
    font-family: Desire-Pro;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 80% */
    width: unset !important;
    margin-bottom: 32px;
  }

  .ParagraphComponent {
    color: #212121;
    font-family: Aileron;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: unset !important;
    margin-bottom: 32px;
  }

  .InputComponent {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #212121;
    background: #fff;
    text-align: center;
  }

  .Mobilebtn {
    width: 335px !important;
    height: 50px !important;
    flex-shrink: 0 !important;
  }

  .ValidateOtpButtonText {
    color: #212121;
    text-align: center;
    font-family: Aileron;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: unset !important;
    height: unset !important;
    flex-shrink: 0;
  }
}