.bannerContainer {
  background-color: #ebe3d1;
  border-radius: 10px;
}
.header1 {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -2px; */
}
.subHeader1 {
  color: #f3a537;
  font-family: Desire-Pro;
  /* font-size: 6em; */
  font-style: normal;
  font-weight: 400;
  /* line-height: 1em; */
  display: contents;
  font-size: 5vw;
  line-height: 64px;
}
.header2 {
  color: #212121;
  font-family: Aileron;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4em !important;
}
.col1 {
  text-align: left;
  padding: 3em !important;
}
.bannerImage {
  height: 100%;
  width: 100%;
}
.col2 {
  padding: 0px !important;
  text-align: right;
}
@media screen and (max-width: 769px) {
  .bannerImage {
    height: 100%;
    width: 100%;
  }

  .header1 {
    font-size: 45px;
  }
  .subHeader1 {
    font-size: 45px;
  }
  .header2 {
    font-size: 16px;
    margin-top: 2em !important;
  }
}
@media screen and (max-width: 426px) {
  .bannerImage {
    height: 100%;
    width: 70vw;
  }
  .bannerContainer {
    background-color: transparent;
  }
  .col1 {
    padding: 10vw !important;
  }
  .col2 {
    background-color: #ebe3d1;
    padding: 0px !important;
  }

  .header1 {
    font-size: 55px;
  }

  .subHeader1 {
    font-size: 55px;
  }
}
