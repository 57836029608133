@font-face {
  font-family: "Desire-Pro";
  src: url("../public/fonts/Desire-Pro.woff") format("woff");
}

body {
  margin: 0;
  font-family: "Desire-Pro", "Aileron";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
}

code {
  font-family: "Aileron", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

ul {
  list-style: none;
}

a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
@media (max-width: 426px) {
  .row {
    --bs-gutter-x: initial !important;
  }
}
