.MainDiv {
  width: 500px;

  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 5rem;
}

.Heading {
  width: 500px;
  height: 28px;
  flex-shrink: 0;
  color: #212121;
  font-family: Desire-Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;

  text-align: left;
  margin-bottom: 34px;
  /* 80% */
}

.Form {
  text-align: left;
}

#InputFieldPassword {
  margin-top: 24px;
}

.FormForgotPassword {
  width: 295px;
  height: 26px;
  flex-shrink: 0;
  color: #212121;
  font-family: Aileron;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;

  margin-top: 10px;
  margin-bottom: 25px;
}

.FormButtonSpan {
  width: 500px;
  height: 20px;
  flex-shrink: 0;
  color: #212121;
  text-align: center;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.FormJoinNow {
  width: 500px;
  height: 18px;
  flex-shrink: 0;
  color: #212121;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-top: 24px;
}

.FormJoinNowLink {
  color: #f3a537;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .OuterDiv {
    width: 80%;
  }

  .Heading {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  .OuterDiv {
    width: 90%;
  }

  .Heading {
    font-size: 24px;
  }
}

@media screen and (max-width: 480px) {
  .OuterDiv {
    width: 95%;
  }

  .Heading {
    font-size: 40px;
  }

  .MainDiv {
    padding: 2em;
    width: 100%;
  }
}
