.bannerContainer {
  /* background-color: #ebe3d1;
  border-radius: 10px; */
  padding: 3em;
}
.header1 {
  color: #212121;

  font-family: Desire-Pro;
  font-size: 5.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -3px; */
}
.header2 {
  color: #212121;
  font-family: Aileron;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 2em 0em !important;
}
.col1 {
  text-align: left;
}
.bannerImage {
  /* height: 450px; */
  max-height: 28em;
  max-width: 28em;
  aspect-ratio: 1;
  /* width: 80%; */
  text-align: right;
  width: 100%;
}
.startButton {
  border: none;
  color: #212121;
  border-radius: 10px;
  background: #f4a537;
  width: auto;
  padding: 10px 15px;
  /* font-family: Aileron; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 769px) {
  .header1 {
    font-size: 3em;
  }
  .header2 {
    font-size: 2vw;
  }
  .minPadding {
    margin-bottom: 2em;
  }
  .bannerImage {
    width: 100%;
  }
}
@media screen and (max-width: 426px) {
  .header2 {
    font-size: 3.7vw;
  }
  .bannerContainer {
    padding: 0em 2em;
  }
}
