.tokenContainer {
    min-height: 12.1875rem;
    padding: 2.5rem;
    /* gap: 1.25rem; */
    border-radius: 1.25rem;
    background: #1E291C;
}

.investmentHeadingContainer {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    flex: 1 0 0;
}

.investmentHeading {
    color: #Fff;
    font-family: Aileron;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    opacity: 0.6;
}

.orderEllipse {
    width: 1.0625rem;
    /* fill: #F3A537; */
}

.tokenBody {
    color: #Fff;
    font-family: Aileron;
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 700;
    text-align: left;
    word-break: break-all;
}

@media screen and (max-width: 767px){
    .tokenContainer{
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        display: block;
        min-height: 7.1875rem;
        /* flex-direction: column; */
        /* align-items: flex-start; */
        /* gap: 1.25rem; */
        border-radius: 0rem;
        background: transparent;
    }
    .orderEllipse{
        display: none;
    }
    .tokenBody{
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        margin-top: .001rem;
    }
    .investmentHeading {
        color: #Fff;
        font-family: Aileron;
        font-size: .85rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1;
        opacity: 0.6;
        margin-bottom: 0.2rem;
        text-align: left;
    }
    .investmentHeadingContainer{
        display: absolute;
        align-items: left;
        gap: 0.625rem;
        width: 100%;
    }
}

@media screen and (max-width: 1500px) {
    .moneyText {
        font-size: 2.3rem;
    }
}

@media screen and (max-width: 425px) {
    .moneyText {
        font-size: 1.5rem;
    }
}