.headerContainer {
  display: flex;
  justify-content: left;
  /* margin-bottom: 4rem;
  margin-top: 3rem; */
}

.header {
  color: #212121;
  text-align: center;
  font-family: Desire-Pro;
  font-size: 3.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  margin-bottom: 1rem;
  /* padding-top: 1em;
  margin-top: 3em; */
  /* 80% */
  /* letter-spacing: -1px; */
}

.Number {
  border-radius: 10px;
  padding: 0px 0.9em;
  margin-right: 0.5em;
  border: 1px solid #ebe3d1;
  background: #fff;
}

.faqBody {
  border-radius: 1.25rem;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 3.125rem 3.125rem 0rem 3.125rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.25rem;
  background-color: #f1f1f1;
}
.accordianMainBody {
  background-color: #f1f1f1;
  padding-bottom: 1em;
}
.moreFAQ {
  color: #212121;
  text-align: right;
  font-family: Aileron;
  font-size: 0.8vw;
  font-weight: 600;
}
.moreFAQ:hover {
  text-decoration: underline;
}
.accordianHeader {
  border-radius: 0.625rem;
  background: #fff;
  margin-bottom: 1em;
}
@media screen and (max-width: 769px) {
  .faqBody {
    border-radius: 0px;
    padding: 1.125rem 1.125rem 0rem 1.125rem;
    gap: 1.25rem;
  }
  .header {
    color: #212121;
    text-align: center;
    font-family: Desire-Pro;
    font-size: 2em;
    font-style: normal;
    font-weight: 200;
    line-height: 48px;
  }
  .moreFAQ {
    font-size: 2.5vw;
  }
}
@media screen and (max-width: 426px) {
  .moreFAQ {
    font-size: 3.5vw;
  }
  .accName {
    line-height: normal;
    font-size: 3.5vw;
  }
}
