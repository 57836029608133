h1 {
    color: white;
}
.order-title{
    color: #fff;
    font-family: Desire-Pro;
    font-size: 6.25rem;
    text-align: left;
}
.orderpage{
    background-color: #1E291C;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    margin: 2rem 2rem 2rem 2rem;
    border-radius: 20px;

}
.order-book-heading{
    color: #fff;
    font-family: Aileron;

}
.order-book-heading{
    color: #fff;
    font-family: Aileron;
}
.form-control{
    background-color: #fff;
}
.disabled-color{
    background-color: #cccccc;
}
.buy-btn {
    background: #f4a537;
    border: transparent;
    color: #212121;
    text-align: center;
    font-family: Aileron;
    font-size: 1.25rem;
    border-radius: 12px;
    font-weight: 700;
    line-height: 15px;
    display: flex;
    align-items: center;
    border: none;
    align-items: center;
    justify-content: center;
    padding: 15px 60px;
    width: 100%;
  }
  
.buy-btn:hover{
    background: #da993e;
    color: #000;
  }

  .add-to-cart-btn {
    background: #ececec;
    border: transparent;
    color: #212121;
    text-align: center;
    font-family: Aileron;
    font-size: 1.25rem;
    border-radius: 12px;
    font-weight: 700;
    line-height: 10px;
    display: flex;
    align-items: center;
    border: none;
    align-items: center;
    justify-content: center;
    padding: 15px 60px;
    width: 100%;
  }
  
.add-to-cart-btn:hover{
    background: #faf9f9;
    opacity: 6;
    color: #000;
  } 
  .sub-heading{
    font-family: Aileron;
    color: #fff;
    font-weight: 400;
    font-size: 1.5rem;
    text-align: left;
  }
  .table-row {
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    border-radius: 15px;
    margin: 10px 0px;
    text-align: center;
  }
  .table-row .table-header {
    font-weight: 600;
    font-size: 20px;
  }
  .table-green {
    color: green;
  }
  .table-red {
    color: red;
  }
 
  .order-th {
    border-bottom-color: transparent !important;
  }
 