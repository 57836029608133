.card {
  border-radius: 20px;
  background: #ebe3d1;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 2rem !important;
  text-align: left;
  height: 23rem;
}
.header {
  color: rgba(33, 33, 33, 0.6);
  font-family: Aileron;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}
.body {
  color: #505050;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  /* font-weight: 600; */
  /* line-height: normal; */
  margin-bottom: 0.8rem;
}
.body:hover {
  cursor: pointer;
  color: #1d3118;
}
