.OuterDiv {
    width: 500px;
  
    margin: 0 auto;
    text-align: left;
    /* background-color: red; */

    padding: 60px 40px;
  }
  
  .Heading {
    width: 500px;
    height: 28px;
    flex-shrink: 0;
    color: #212121;
    font-family: Desire-Pro;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  
    margin-bottom: 34px;
    /* 80% */
  }
  
  .Paragraph {
    flex-shrink: 0;
    color: #212121;
    font-family: Aileron;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  
    margin-bottom: 20px;
  }
  
  .Form {
    text-align: left;
  }
  
  .InputField {
    margin-top: 24px;
  }
  
  .CancelButton {
    width: 241.935px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #1d3118;
    margin-top: 40px;
  }
  
  
  .CancelDiv {
    text-align: center;
    margin-top: 20px;
  }
  
  .ButtonTextReset {
    width: 229.032px;
    height: 20px;
    flex-shrink: 0;
    color: #212121;
    text-align: center;
    font-family: Aileron;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .ButtonTextCancel {
    height: 18px;
    align-self: stretch;
    color: #212121;
    text-align: center;
    font-family: Aileron;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* width: 229.032px;
    height: 20px;
    flex-shrink: 0;
    color: #212121;
    text-align: center;
    font-family: Aileron;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; */
  }

  .modalContent {
    width: 580px !important;
    border-radius: 20px;
  }
  .cancelButton {
    border: 0;
    background: white;
  }

  .InputComponent {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #212121;
    background: #fff;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 20px;
  }


  .labelContainer {
    display: flex;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #000000d9;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 3px;
  }
  .label {
    color: #212121;
    margin-bottom: 0.5em;
    font-family: Aileron;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }