.bannerContainer {
  background-color: black;
  border-radius: 10px;
  padding: 3em;
}
.header1 {
  text-align: left;
  font-family: Desire-Pro;
  font-size: 7.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  color: #fff;
  padding: 0px 1em 0px 0px;
  margin-bottom: 0.5em;
}
.header2 {
  color: #f3a537;
  font-family: Desire-Pro;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -3px;
  display: contents;
}
.subHeader1 {
  color: #fff;
  /* font-family: Desire-Pro; */
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}
.col1 {
  /* margin-left: 1em; */
}
.bannerImage {
  height: 100%;
  width: 30vw !important;
}
.imageContainer {
  border-radius: 10px;
  background: #ebe3d1;
  /* background-image: url("../../../public/images/contact-us-phone.svg"); */
}

.getInTouch {
  border-radius: 10px;
  background: #1c2a4d;
  padding: 2rem;
  /* margin: 0px 2rem; */
}
.textLeft {
  text-align: start;
  padding: 2em 0px;
}
.tag {
  color: #fff;
  font-family: Aileron;
  font-size: 1.1em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.tag:hover {
  color: #f3a537;
}

.miniLine1 {
  text-align: left;
  font-family: Desire-Pro;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -1px; */
  color: #f3a537;
  display: flex;
}
.miniLine2 {
  color: #fff;
  font-family: Desire-Pro;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -1px; */
  display: contents;
}

@media screen and (max-width: 767px) {
  .header1 {
    font-size: 4em;
    padding: 0px;
  }
  .subHeader1 {
    font-size: 1.5em;
  }
  .bannerImage {
    height: 100%;
    margin: auto;
  }
  .miniLine1 {
    justify-content: center;
  }
}

@media screen and (max-width: 426px) {
  .header1 {
    font-size: 50px;
  }
  .subHeader1 {
    font-size: 17px;
  }
  .textLeft {
    gap: 10px;
  }
  .tag {
    font-size: 17px;
    font-weight: 400;
  }
  .bannerImage {
    width: 241px !important;
    height: 388px !important;
  }
  .miniLine1 {
    font-size: 6.5vw;
  }
  .miniLine2 {
    font-size: 6.5vw;
  }
  .bannerContainer {
    border-radius: 0px;
  }
}
