.bannerContainer {
  /* background-color: #ebe3d1; */
  /* border-radius: 10px; */
  padding: 6em 3em 10em 3em;
}
.header1 {
  color: #212121;

  font-family: Desire-Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -2px; */
  display: flex;
  justify-content: center;
  margin-bottom: 0.7em !important;
}
.diffColor {
  color: #f4a537;
  font-family: Desire-Pro;
  margin-left: 10px;
}
.header2 {
  color: #212121;
  font-family: Aileron;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin-top: 2em !important; */
  width: 70%;
  margin: auto !important;
}
.col1 {
  text-align: center;
}
.bannerImage {
  height: 300px;
}

@media screen and (max-width: 426px) {
  .bannerContainer {
    padding: 0em 2em;
  }
  .header2 {
    width: 100%;
    text-align: left;
  }
}
