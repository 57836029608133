.bannerContainer {
  padding: 2em;
}
.header1 {
  color: #212121;

  font-family: Desire-Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -2px; */
  display: flex;
  justify-content: left;
  text-align: start;
}
.header2 {
  color: #212121;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin-top: 2em !important; */
  /* width: 70%; */
  margin: auto !important;
  text-align: left;
  font-family: Aileron;
}
.col1 {
  text-align: center;
  margin-top: 3rem;
}
.bannerImage {
  height: 300px;
}
.diffColor {
  color: #f4a537;
  font-family: Desire-Pro;
  margin-left: 10px;
}
.middleText {
  display: flex;
  vertical-align: middle;
}
.mobileView {
  margin-top: 40px;
  /* margin-bottom: 40px; */
}
