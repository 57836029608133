.LayoutContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  /* align-self: center; */
  text-align: center;
  /* width: 100%; */
  /* height: 100vh; */
  /* padding: 0 10px; */
  gap: 15px;
  flex-wrap: nowrap;
}

/* .SideBarContainer {
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  flex-direction: column;
  gap: unset;
  flex: 15%;
  height: 100vh;
  padding: 10px;
  padding: 15px;
  border-radius: 15px;
  background: rgba(55, 47, 141, 0.5);
  margin-top: 15px;
  max-height: 90vh;
} */

/* .OppositeSidebar {
  display: flex;
  flex: 100%;
  flex-direction: column;
  padding: 15px;
  border-radius: 15px;
  background: rgba(55, 47, 141, 0.5);
  margin-top: 15px;
} */

.PageBody {
  overflow: auto;
  /* top: 80px; */
  /* position: absolute; */
  width: -moz-available;
  /* height: 2000px; */
  padding: 0em 2em 2em 2em;
}

@media screen and (max-width: 769px) {
  .PageBody {
    padding: 0em;
  }
}

.bottomNav {
  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.15);
  /* position: unset !important; */
}