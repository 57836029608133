.MainDiv {
    width: 500px;
    height: 289px;
    margin: 0 auto;
    /* background-color: red; */
  }
  
  .SuccessIcon {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
  }
  
  .Heading {
    width: 500px;
    height: 28px;
    flex-shrink: 0;
    color: #212121;
    text-align: center;
    font-family: Desire-Pro;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-top: 29px;
    /* 80% */
  }
  
  .Paragraph {
    width: 500px;
    height: 26px;
    flex-shrink: 0;
    color: #212121;
    text-align: center;
    font-family: Aileron;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 34px;
  }
  
  .ButtonText {
    width: 474.194px;
    height: 20px;
    flex-shrink: 0;
    color: #212121;
    text-align: center;
    font-family: Aileron;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  