.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.Loader {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.bg-grey {
  opacity: 0.5;
}
.unclickable {
  pointer-events: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e") !important;
  transform: scale(0.7) !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e") !important;
}
.accordion-button:not(.collapsed) {
  background-color: #ebe3d1 !important;
  color: #212121 !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-item {
  border-radius: 10px 10px 10px 10px !important;
  border: transparent !important;
  background-color: #ebe3d1 !important;
  color: #505050 !important;
  /* font-family: Aileron; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.accordion-button {
  border: transparent !important;
  color: #212121;
  /* font-family: Aileron; */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}
.accordion-body {
  text-align: left;
}
.main-container {
  max-width: 1600px;
  margin: auto;
}
