.HeaderContainer {
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  align-items: center;
  z-index: 1000;
  background-color: #1d3118;

  padding-left: 20px;
  padding-right: 20px;
}

.PurchaseHeaderContainer {
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  align-items: center;
  z-index: 1000;
  background-color: #000;
  /* border-bottom: 1px solid #50644B; */

  padding-left: 20px;
  padding-right: 20px;
}

.OthersHeaderContainer {
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  align-items: center;
  z-index: 1000;
  background-color: #ffffff;
  /* border-bottom: 1px solid #50644B; */

  padding-left: 20px;
  padding-right: 20px;

  height: 91px;
  padding-top: 20px;
  justify-content: space-around;
}

.Logo {
  padding-left: 15px;
  padding-right: 15px;
}

.DesktopMenuSection {
  /* padding-right: 20px; */
  display: flex;
  gap: 10px;
  align-items: center;
  height: 80px;
}

.DesktopMenu {
  color: rgba(255, 255, 255, 0.2);
  text-align: center;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 100%;
  flex-shrink: 0;
  padding: 5px 3px;
  line-height: 4px;
  /* border-bottom: 2px solid #fff; */
  margin-right: 30px;
  /* margin-top: 11px; */
  vertical-align: middle;
  cursor: pointer;
  transition: fill 0.3s ease;
  display: flex;
  align-items: center;
}

.DesktopMenu:hover {
  border-top: 2px solid #f4a537;
  color: rgba(255, 255, 255, 0.2);
}
.DesktopMenu:hover .iconImg {
  /* filter: invert(100%); */
}

.MobileMenuIcon {
  display: none;
  cursor: pointer;
  /* background-color: #000; */
  /* color: #1d3118; */
}

.MobileLoginMenu {
  display: none;
  height: 20px;
  flex-shrink: 0;
  color: #1d3118;
  text-align: center;
  font-family: Aileron;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 4px;
  border-bottom: 2px solid #f4a537;
}

.MobileLoginMenu:hover {
  color: #d57900;
}

.MobileMenuOverlay {
  display: none;
}
.iconImg {
  /* width: 24px; */
  margin-right: 10px;
}
.iconImg:hover {
  color: #fff;
}
.Logout {
  background-color: transparent !important;
  border: transparent !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center;
  gap: 10px;
}
.Logout:hover {
  background-color: transparent;
  border: 1px solid #fff !important;
}
.Logout:focus {
  background-color: transparent;
  border: 1px solid #fff !important;
}
.dropdownIcon {
  width: 30px;
  height: 30px;
  background-color: grey;
  border-radius: 50%;
}
.custom_dropdown_arrow {
  margin-left: 5px; /* Adjust the spacing between text and arrow */
  font-size: 12px; /* Adjust the size of the arrow */
  /* Add additional styles as needed */
}
@media screen and (max-width: 1300px) {
  /* Overlay */
  .MobileMenuOverlay {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    background-color: #1d3118;
    overflow: hidden;
    transition: 0.5s;
  }

  .MobileMenuOverlay .CloseBtn {
    top: 42px;
    left: 20px;
    /* padding: 5px; */
    /* margin-left: -40px; */
    text-align: left;
  }

  .OverlayContent {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 38px;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0px;
    justify-content: space-between;
    height: inherit;
  }

  .TopMobileCotent {
    display: flex;
    flex-direction: column;
  }

  .MobileMenu {
    display: flex;
    flex-direction: column;
    gap: 35px;
    /* color: #ebe3d1; */
    /* font-family: Desire-Pro; */
    /* font-size: 40px; */
    font-style: normal;
    /* font-weight: 400; */
    /* line-height: 32px; */
    align-self: flex-start;
    text-align: left;

    color: #FFF;
    font-family: Aileron;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
  }

  .MobileMenu:hover {
    color: #ebe37f;
  }

  .MobileWhiteMenu {
    display: flex;
    flex-direction: column;
    gap: 35px;
    color: #000000;
    /* font-family: Desire-Pro; */
    /* font-size: 40px; */
    font-style: normal;
    /* font-weight: 400; */
    /* line-height: 32px; */
    align-self: flex-start;
    text-align: left;

    /* color: #FFF; */
    font-family: Aileron;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
  }

  .MobileMenubtn {
    background: unset;
    border: unset;
    padding: 0px !important;
    padding-top: 20px !important;
  }

  .MobileBtnContainer {
    display: flex;
    gap: 10px;
    margin-top: 40px;
    flex-wrap: wrap;
  }

  /* Overlay */

  .HeaderContainer {
    height: 91px;
    padding-top: 20px;
    justify-content: space-between;
  }

  .MobileMenuIcon {
    display: block;
  }

  .DesktopMenuSection {
    display: none;
  }

  .MobileLoginMenu {
    display: block;
    visibility: hidden;
  }

  .DesktopMenu:hover {
    padding: 0px;
    border: 0px;
  }

  .DesktopMenu, .dropMenu {
    padding: 0px !important;
  }
}

.PurchaseMobileMenuOverlay {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  background-color: #000000;
  overflow: hidden;
  transition: 0.5s;
}

.PurchaseMobileMenuOverlay .CloseBtn {
  top: 42px;
  left: 20px;
  /* padding: 5px; */
  /* margin-left: -40px; */
  text-align: left;
}

.OthersMobileMenuOverlay {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  background-color: #ffffff;
  overflow: hidden;
  transition: 0.5s;
}

.OthersMobileMenuOverlay .CloseBtn {
  top: 42px;
  left: 20px;
  /* padding: 5px; */
  /* margin-left: -40px; */
  text-align: left;
}

.MobileMenuHeader {
  display: flex;
  padding-bottom: 40px;
  /* padding: 44px 24px 16px 24px; */
  /* align-items: center; */
}

.mobileMenuList {
  display: flex;
  gap: 12px;
}

.dropMenu {
  background-color: transparent !important;
  border: unset !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center;
  gap: 10px;

  color: rgba(255, 255, 255, 0.2) !important;
  text-align: center;
  font-family: Aileron !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.dropItems {
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.tabView {
  align-self: center;
}