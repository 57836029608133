.HeaderContainer {
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  justify-content: space-between;
  height: 114px;
  width: 100%;
  align-items: center;
  z-index: 1000;
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
}

.Logo {
  padding-left: 15px;
  padding-right: 15px;
}

.DesktopMenuSection {
  /* padding-right: 20px; */
  display: flex;
  gap: 10px;
  align-items: center;
}

.DesktopMenu {
  color: #212121;
  text-align: center;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 23px;
  flex-shrink: 0;
  padding: 5px 3px;
  line-height: 4px;
  border-bottom: 2px solid #fff;
  margin-right: 30px;
  margin-top: 11px;
}

.DesktopMenu:hover {
  border-bottom: 2px solid #f4a537;
  color: #212121;
}

.MobileMenuIcon {
  display: none;
  cursor: pointer;
}

.MobileLoginMenu {
  display: none;
  height: 20px;
  flex-shrink: 0;
  color: #1d3118;
  text-align: center;
  font-family: Aileron;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 4px;
  border-bottom: 2px solid #f4a537;
}

.MobileLoginMenu:hover {
  color: #d57900;
}

.MobileMenuOverlay {
  display: none;
  overflow-y: scroll !important;
}

.mobileSideNavFooter {
  padding-bottom: 80px;
}

@media screen and (max-width: 996px) {
  /* Overlay */
  .MobileMenuOverlay {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    background-color: #1d3118;
    overflow: hidden;
    transition: 0.5s;
  }

  .MobileMenuOverlay .CloseBtn {
    top: 42px;
    left: 20px;
    padding: 5px;
    margin-left: -40px;
    text-align: left;
  }

  .OverlayContent {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 38px;
    width: 100%;
    padding: 60px;
    padding-top: 0px;
    justify-content: space-between;
    height: inherit;
  }

  .TopMobileCotent {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 2em;
  }

  .MobileMenu {
    display: flex;
    flex-direction: column;
    gap: 35px;
    color: #ebe3d1;
    font-family: Desire-Pro;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    align-self: flex-start;
    text-align: left;
  }

  .MobileMenu:hover {
    color: #ebe37f;
  }

  .MobileBtnContainer {
    display: flex;
    gap: 10px;
    /* flex-wrap: wrap; */
    width: 50%;
  }

  .MobileBtns {
    width: 100px !important;
    height: 48px !important;
  }

  /* Overlay */

  .HeaderContainer {
    height: 91px;
    padding-top: 20px;
    justify-content: space-between;
  }

  .MobileMenuIcon {
    display: block;
  }

  .DesktopMenuSection {
    display: none;
  }

  .MobileLoginMenu {
    display: block;
  }
}
