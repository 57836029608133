.header {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  text-align: start;
}
.body {
  color: #212121;
  font-family: Aileron;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 2rem 0em;
}
