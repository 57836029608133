.bannerContainer {
  /* background-color: #ebe3d1; */
  /* border-radius: 10px; */
  padding: 8em 3em 5em 3em;
}
.header1 {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -2px; */
  display: flex;
  justify-content: center;
  margin-bottom: 0.7em !important;
}
.diffColor {
  color: #f4a537;
  font-family: Desire-Pro;
  margin-left: 10px;
}
.header2 {
  color: #212121;
  font-family: Aileron;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin-top: 2em !important; */
  width: 50%;
  margin: auto !important;
}
.col1 {
  text-align: center;
}
.bannerImage {
  height: 300px;
}
.links {
  color: #212121;
  font-weight: 600;
}
.links:hover {
  color: #212121;
}
@media screen and (max-width: 769px) {
  .header1 {
    font-size: 5vw;
  }
  .header2 {
    font-size: 2.5vw;
    width: 80%;
  }
}
@media screen and (max-width: 426px) {
  .bannerContainer {
    padding: 2em 2em 0em 2em;
  }
  .header1 {
    text-align: left;
    font-size: 40px;
  }
  .diffColor {
    font-size: 40px;
  }
  .header2 {
    width: 100%;
    text-align: left;
    font-size: 3.2vw;
  }
}
