.bannerContainer {
  padding: 3em 0em;
}
.header1 {
  color: #212121;

  font-family: Desire-Pro;
  font-size: 4em;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -2px;
}
.header2 {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 2em !important;
}
.col1 {
  text-align: left;
}
.bannerImage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.bannerImage1 {
  width: 80%;
  margin: 1 rem;
}
.banner1 {
  background-color: #ebe3d1;
  border-radius: 10px;
  height: 20vw;
  margin: 10px 0em;
}
.banner2 {
  background-color: #1c2a4d;
  border-radius: 10px;
  color: #fff;
  text-align: left;
  /* font-family: Aileron; */
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1em;
  height: 20vw;
  margin: 10px 0em;
}

.banner3 {
  background-image: url("../../../Assets/Images/about-banner-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  color: #fff;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 1.5em 1em 1em 1em;
  display: flex;
  text-align: center;
  align-items: center;
  height: 20vw;
  margin: 10px 0em;
  flex-direction: column;
  gap: 1em;
}
.subBanner3 {
  font-size: 1vw;
}

.banner4 {
  background-color: #ebe3d1;
  border-radius: 10px;
  color: #212121;
  text-align: left;
  /* font-family: Aileron; */
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 1em;
  height: 20vw;
  margin: 10px 0em;
}

.banner5 {
  background-image: url("../../../Assets/Images/about-banner-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  color: #fff;
  font-size: 2em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 1em;
  display: flex;
  text-align: center;
  align-items: center;
  height: 20vw;
  margin: 10px 0em;
}
.banner6 {
  background-color: #1c2a4d;
  border-radius: 10px;
  color: #fff;
  text-align: left;
  /* font-family: Aileron; */
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  padding: 1em;
  height: 20vw;
  margin: 10px 0em;
}
.banner7 {
  background-color: #1c2a4d;
  background-image: url("../../../Assets/Images/about-banner-7.svg");
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: center;
  border-radius: 10px;
  height: 20vw;
  margin: 10px 0em;
  padding: 2em;
}
.carImg {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .banner2 {
    font-size: 4vw;
    margin: 0px;
    background-color: #ebe3d1;
    border-radius: 0px;
    color: #212121;
    height: 30vw;
  }
  .bannerContainer {
    padding: 0em;
  }
  .bannerImage1 {
    max-height: 200px;
    max-width: 500px;
    margin: 1.5rem;
  }
  .banner1 {
    margin: 0px;
    border-radius: 0px;
    height: 40vw;
  }
  .banner1 img {
    width: 70%;
    height: 70%;
    margin-top: 4em;
  }
  .banner4 {
    background-color: #1c2a4d;
    margin: 0px;
    border-radius: 0px;
    color: #fff;
    font-size: 2em;
    font-weight: 400;
    height: 40vw;
  }
  .banner3 {
    margin: 2em;
    /* padding: 2em; */
    font-size: 5vw;
    height: 40vw;
  }
  .bgPink {
    background-color: #ebe3d1;
  }
  .bgBlue {
    background-color: #1c2a4d;
  }
  .banner6 {
    margin: 0px;
    border-radius: 0px;
    height: 40vw;
  }
  .subBanner3 {
    font-size: 2vw;
  }
}
@media screen and (max-width: 426px) {
  .banner1 {
    height: 100%;
  }
  .banner1 img {
    margin: 2em 0em;
    width: 90%;
    height: 80%;
  }
  .banner2 {
    color: #212121;
    height: 100%;
    font-family: Aileron;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 1em 2em;
  }
  .subBanner3 {
    font-size: 0.5em;
  }
  .banner4 {
    font-size: 17px;
    height: 100%;
    padding: 1em 2em;
  }
  .banner3 {
    margin: 1em;
    /* padding: 2em; */
    height: 200px;
    font-size: 1.8em;
  }
  .bgPink {
    background-color: #ebe3d1;
  }
  .bgBlue {
    background-color: #1c2a4d;
  }
  .banner6 {
    margin: 0px;
    height: 100%;
    border-radius: 0px;
  }
  .banner6 img {
    width: 239.003px;
    height: 134.666px;
  }
}
