.infoContainer {
  /* background-color: black;
  border-radius: 10px; */
  padding: 0em 3em 3em 3em;
  text-align: left;
}
.header1 {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -3px; */
  display: flex;
  justify-content: left;
  margin-bottom: 0.5em !important;
}
.diffColor {
  color: #f4a537;
  font-family: Desire-Pro;
  margin-left: 10px;
}
.subheader {
  color: #212121;
  font-family: Aileron;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 1em 0em 0em 0em;
}
.body {
  margin: 2em 0em;
}
@media screen and (max-width: 480px) {
  .header1 {
    display: block;
  }
  .diffColor {
    margin: 0px;
  }
}
