.modalBody {
  border-radius: 20px !important;
  padding: 40px 40px 60px 40px !important;
}
.header1 {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.downloadArrow {
  background-color: transparent;
  border: none;
}

@media (max-width: 426px) {
  .modalBody {
    display: flex;
    /* width: 335px; */
    padding: 40px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    border-radius: 20px;
    background: #fff;
  }

  .header1 {
    color: #212121;
    font-family: Desire-Pro;
    font-style: normal;
    margin-bottom: 1em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    font-size: 28px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 12px !important;
  }

  .mobileRow {
    width: 100%;
  }
}
