.GetInTouchContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: stretch;
  width: 45%;
}

.GetInTouchHeading {
  color: #fff;
  font-size: 25px;
  font-family: Aileron;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
}

.GetInTouchDescription,
.ErrorMessage {
  color: #fff;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.GetInTouchFormContainer {
  display: flex;
  gap: 20px;
  flex-shrink: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
}

.FormFields {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  flex: auto;
}

.ContainerItemsLeft {
  display: flex;
  gap: 10px;
  flex-direction: column;
  flex: 28% 0;
}

.ContainerItemsRight {
  flex: 55% 0;
}

.FormInput {
  max-width: 315px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 10px;
  background: #fff;
}

.FormTextArea {
  min-width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 10px;
  background: #fafafa;
}

.FormTextArea::placeholder {
  /* Most modern browsers support this now. */
  color: rgb(192, 190, 192);
}
.FormTextArea:focus {
  outline: none !important;
  border: 1px solid rgb(247, 247, 247);
  /* box-shadow: 0 0 10px #f8f8f8; */
}

@media screen and (max-width: 1600px) {
  .GetInTouchContainer {
    width: 60%;
  }
}

@media screen and (max-width: 1200px) {
  .GetInTouchContainer {
    width: 75%;
  }
}

@media screen and (max-width: 996px) {
  .GetInTouchHeading {
    margin-top: 20px;
  }

  .GetInTouchContainer {
    justify-content: flex-start;
  }

  .FormFields,
  .ContainerItemsLeft {
    gap: 25px;
  }

  .FormTextArea {
    height: 200px;
  }

  .GetInTouchContainer {
    width: 100%;
  }

  .ContainerItemsLeft {
    flex: 50% 0;
  }

  .ContainerItemsRight {
    flex: 90% 0;
  }

  .FormInput {
    max-width: 100%;
  }

  .GetInTouchDescription,
  .ErrorMessage {
    max-width: max-content;
  }
}

@media screen and (max-width: 767px) {
  .ContainerItemsLeft,
  .ContainerItemsRight {
    flex: 100% 0;
  }
}
