.alerts {
  background: #fff !important;
  border-radius: 20px;
  display: flex;
  width: 520px;
  padding: 20px 16px 32px 50px;
  align-items: flex-start;
  gap: 38px;
}

.success-alert {
  /* border: 1px solid #112750 !important; */
  color: #212121;
  z-index: 1111;
  border-left: 20px solid #008b3c !important;

  background: #fff;
}

.error-alert {
  color: #212121;
  border-left: 20px solid #ce0809 !important;
  z-index: 1111 !important;
}
.top-5 {
  margin-top: 5rem;
}
.cursor-pointer {
  cursor: pointer;
}
