.dashboardBody {
  background: #1d3118;
  border-top: 1px solid #50644b;
  width: 100%;
}

.mainBody {
  padding: 2rem 3rem 3rem 2rem;
}
.gap {
  row-gap: 2.5rem;
  /* column-gap: 0.00rem; */
  margin-bottom: 2rem;
  margin-top: 2.4rem;
}
.deskView{
  display: block;
}
.mobileView{
  display: none;
}

@media screen and (max-width: 767px) {
  .mainBody {
    padding: 1rem 0rem 0rem 0rem;
  }
  .gap {
    row-gap: 0rem;
    column-gap: 0rem;
  }
  .deskView{
    display: none;
  }
  .mobileView{
    display: block;
  }
}
