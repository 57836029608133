.bannerContainer {
  /* background-color: #ebe3d1; */
  /* border-radius: 10px; */
  padding: 8em 3em 5em 3em;
}
.header1 {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 3.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -2px; */
  display: flex;
  justify-content: center;
  margin-bottom: 0.7em !important;
}
.diffColor {
  color: #f4a537;
  font-family: Desire-Pro;
  margin-left: 10px;
}
.header2 {
  color: #212121;
  /* font-family: Desire-Pro; */
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin-top: 2em !important; */
  width: 50%;
  margin: auto !important;
}
.col1 {
  text-align: center;
}
.bannerImage {
  height: 300px;
}
@media screen and (max-width: 426px) {
  .bannerContainer {
    padding: 2vw;
  }
  .header2 {
    width: 100%;
    font-size: 3.5vw;
  }
  .header1 {
    font-size: 10.5vw;
  }
}
