.bannerContainer {
  /* background-color: #ebe3d1; */
  /* border-radius: 10px; */
  padding: 3em;
}
.header1 {
  color: #212121;

  font-family: Desire-Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -2px; */
  display: flex;
  justify-content: center;
  margin-bottom: 0.7em !important;
}
.header2 {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin-top: 2em !important; */
  /* width: 70%; */
  margin: auto !important;
}
.col1 {
  text-align: center;
}
.bannerImage {
  height: 300px;
}
.diffColor {
  color: #f4a537;
  font-family: Desire-Pro;
  margin-left: 10px;
}
.imageContainer {
  align-self: center;
}
.textInfo {
  text-align: left;
  color: #212121;
  font-family: Aileron;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding: 10px 0px;
}

.subText {
  width: unset !important;
}

ul li {
  list-style: disc !important;
  list-style-position: outside !important;
  margin: 20px 0px;
}