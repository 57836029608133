.card {
  border-radius: 20px;
  background: #ebe3d1;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 2rem !important;
  text-align: left;
  height: 26rem;
}

.header {
  color: rgba(33, 33, 33, 0.6);
  font-family: Aileron;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.body {
  color: #505050;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  /* font-weight: 600; */
  /* line-height: normal; */
  margin-bottom: 0.8rem;
}

.submitBtn {
  width: 7.1875rem;
  height: 2.5rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: #1D3118;
  color: #fff;
}

.counter {
  width: 100%;
  padding-right: 8px;
  color: #a3a29d;
  font-style: italic;

}
.messageBox{
  max-height: 120px;
}

@media screen and (max-width: 767px) {
  .fields{
    background-color: #a8a8a8;
  }
  .messageBox{
    background-color: #a8a8a8;
  }
  .card {
    background: #fcfcfc;
  }
  .submitBtn {
    background: #f4a537;
    color: #000;
    border: transparent;
    color: #212121;
    text-align: center;
    font-family: Aileron;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}