.container{
    background: #1d3118;
    border-top: 1px solid #50644b;
    min-height: 760px;
    padding: 5rem 22.625rem 0rem 15.625rem;
}
.body{
    background-color: #1d3118;
}
.panelContainer{   
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1em;
    border-radius: 15px;
}

.img{
    width: 150px;
    height: 150px;
    border-radius: 15px;
}
.collectionDiv{
    padding-right: 1rem;
    margin-right: 3rem;
    border-radius: 15px;
    background-color:#f8f9fa;
    height: 100%;
    /* padding-top: 1em;
    padding-bottom: 1em; */
    padding-left: 1.5em;
}

.collectionPanel{
    border-radius: 1.25rem;
    background: #1E291C;
}
.collectionPanelBody{
    padding-top: 1.5rem;
    min-height: 500px;
}

.collectionPanelFooter{
    border-top: 1px solid #50644b;
    min-height: 5rem;
}


.header {
    color: #fff;
    font-family: Desire-Pro;
    font-size: 6.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 5rem;
  }
  
  .headerContainer {
    text-align: left;
    padding-top: 0.5em;
    padding-bottom: 1.5em;
    display: flex;
    justify-content: space-between;
  }
.btn1{
    width: 90%;
    border-radius: 5px;
    padding: 4px;
    margin-top: 15px;
    margin-bottom: 10px;
    background: #f4a537;
    color: #000;
    border: transparent;

}
.btn2{
    width: 90%;
    border-radius: 5px;
    padding: 4px;
    margin-bottom: 10px;
    background: #f4a537;
    color: #000;
    border: transparent;  

}
.btn3{
    width: 90%;
    border-radius: 5px;
    padding: 4px;
    margin-bottom: 2px;
    background: #f4a537;
    color: #000;
    border: transparent;

}
.btn1:hover{
    background: #da993e;
    color: #000;
}
.btn2:hover{
    background: #da993e;
}
.btn3:hover{
    background: #da993e;
}
.panelHeader{
    text-align: left;
    font-size: 1.5rem;
    font-weight: 500;
    padding-top: 0.3em;
}
.panelHeading{
    font-weight: 700;
    text-align: left;

}
.panelValue{
    font-weight: 400;
    text-align: left;

}

.toggle {
    color: #fff;
    padding-right: 5px;
}

.togglebtn {
    align-items: center;
    display: flex;
    justify-content: end;
    margin-top: auto;
}

.toggleHeader {
    text-align: right;
    padding-bottom: 10px;
    display: flex;
}