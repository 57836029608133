.MainDiv {
  width: 503px;

  margin: 0 auto;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 5rem;
}

.Heading {
  width: 500px;
  height: 28px;
  flex-shrink: 0;
  color: #212121;
  font-family: Desire-Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;

  margin-bottom: 34px;
}
.label {
  color: #212121;
  margin-bottom: 0.5em;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Paragraph {
  /* width: 238px; */
  height: 26px;
  flex-shrink: 0;
  color: #212121;
  font-family: Aileron;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-bottom: 23px;
}

.Form {
  text-align: left;
}

.InputField {
  margin-top: 24px;
}

.RegisterButtonText {
  width: 500px;
  height: 20px;
  flex-shrink: 0;
  color: #212121;
  text-align: center;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.RouteDiv {
  width: 500px;
  height: 18px;
  flex-shrink: 0;
  color: #212121;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-top: 24px;
}

.Route {
  color: #f3a537;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.CheckBox {
  width: 20px;
  height: 20px;
  border: 1px solid #212121;
}

.CheckBoxText {
  flex: 1 0 0;
  color: #212121;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.PolicyDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
@media screen and (max-width: 480px) {
  .MainDiv {
    width: 100%;
    padding: 2em;
  }
}
