.tab {
  border-radius: 10px;
  padding: 24px 32px;
  text-align: left;
}

.key {
  color: #212121;

  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.valueCol {
  align-self: center;
}
.value {
  color: #212121;

  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;

  width: 100%;
}
.imgWrapper {
  width: fit-content;
}
.inputField {
  width: 100%;
  border: none;
  background-color: transparent;
}
.inputField:focus-visible {
  outline: none !important;
}
.editImg {
  cursor: pointer;
}
.kycToolTip {
  color: #fff;
  text-decoration: underline;
}
.kycToolTip2:hover {
  color: #f4a537 !important;
}

.countrySelect {
  border: 0px;
  background: transparent;
  /* text-align: right; */
  font-family: Aileron;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.dropdownText {
  /* text-align: right; */
  font-family: Aileron;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 764px) {
  .tab {
    border-radius: 10px;
    padding: 12px 16px;
    text-align: left;
  }
}

@media (max-width: 1184px) {
  .tab {
    border-radius: 10px;
    padding: 12px 16px;
    text-align: left;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
}
.labelContainer {
  display: flex;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #000000d9;
  width: 100%;
}
.label {
  color: #212121;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.tooltipText {
  color: #000000d9;
}
.inputBox {
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.4);

  background: #fff;
  padding: 12px 16px 12px 20px;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  &::placeholder {
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #00000040;
  }

  &:focus {
    border: 1px solid #212121;
    outline: 1px solid #212121;
  }
}

.inputFieldContainer {
  width: 100%;
  position: relative;
  display: flex;
}

.toggleButton {
  border: transparent;
  text-decoration: underline;
  background-color: transparent;
  margin-left: -2rem;
  color: #212121;

  /* text-align: right; */
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mobilecountrySelect {
  border: 0px;
  background: transparent;
  /* text-align: right; */
  font-family: Aileron;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.4);
  padding: 12px 16px 12px 20px;
  width: 100%;
}

.labelText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobileInputBox {
  padding-right: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tooltipText {
  display: flex;
}

.noteText {
  color: red;
  font-weight: 200;
  font-size: smaller;
  display: flex;
}

.copyBtn {
  background: unset;
  border: unset;
  padding: 0px;
  margin: unset;
  align-items: flex-start;
  display: flex;
  color: red;
}

.tooltip {
  max-width: 700px !important;
  width: 700px !important;
}

.mobileBtn {
  width: 10px;
  margin-left: -4rem;
  margin-top: auto;
  margin-bottom: auto;
}

.mobileCopy {
  width: 10px;
  margin-left: -2rem;
  margin-top: auto;
  margin-bottom: auto;
}
