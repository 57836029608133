.bannerContainer {
  background: transparent;
  border-radius: 10px;
  padding: 2em;
  text-align: left;
}
.header1 {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -2px; */
  display: flex;
  justify-content: left;
  margin-bottom: 0.7em !important;
}
.header2 {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -2px; */
  display: flex;
  justify-content: left;
  margin-bottom: 0.7em !important;
  margin-top: 0.7em !important;
}

.card {
  margin-top: 1em;
  border-radius: 15px !important;
  background-color: #344630 !important;
  color: #ebe3d1 !important;
  text-align: left;
}
.cardContainer {
  display: flex;
  gap: 1em;
}
.cardBody {
  padding: 2em;
}
