.bannerContainer {
  background-color: black;
  border-radius: 10px;
  padding: 3em;
}
.header1 {
  color: #f3a537;
  text-align: left;
  font-family: Desire-Pro;
  font-size: 6vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -1px; */
}
.header2 {
  color: #fff;
  font-family: Desire-Pro;
  font-size: 5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  /* letter-spacing: -3px; */
  display: contents;
}
.header3 {
  color: #fff;
  font-size: 1.5vw;
  font-family: Aileron;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
}

.subHeader1,
.subHeader5 {
  color: #fff;
  font-size: 1.4vw;
  font-family: Aileron;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 2vw;
  margin-top: 1.5em !important;
}
.subHeader2 {
  color: #fff;
  font-size: 1rem;
  font-family: Aileron;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  margin-bottom: 1rem !important;
  /* margin-bottom: 1em !important; */
}
.subHeader3 {
  color: #fff;
  font-size: 1.2rem;
  font-family: Aileron;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  margin: 0.2em 0em !important;
}
.subHeader4 {
  color: #fff !important;
  font-size: 1.2rem;
  font-family: Aileron;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  padding: 0px !important;
}
.subHeader4:hover {
  color: #fff;
}

.col1 {
  /* margin-left: 1em; */
}
.bannerImage {
  height: 25vw;
}
.imageContainer {
  border-radius: 10px;
  background: #ebe3d1;
  height: 100% !important;
  /* background-image: url("../../../public/images/contact-us-phone.svg"); */
}
.imgBorder {
  border-radius: 10px;
}

.ContactUsContainer {
  border-radius: 10px;
  background: #1c2a4d;
  padding: 3rem;
  height: 100%;
  display: block;
  /* margin: 0px 2rem; */
}
.minPadding {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.blackBackGround {
  padding-left: 0px !important;
}
.image {
  width: 100%;
  max-width: 80px;
}
.imageCol {
  align-self: center;
}
@media screen and (max-width: 426px) {
  .imageContainer {
    width: 95vw;
  }
  .bannerContainer {
    margin: 0px !important;
    border-radius: 0px;
  }
  .header1 {
    font-size: 55px;
  }
  .header2 {
    font-size: 55px;
  }
  .minPadding {
    margin: 0px !important;
    background-color: black;
  }
  .ContactUsContainer {
    background: white;
    border-radius: 0px;
    margin-top: 1rem !important;
    justify-content: center;
  }
  .subHeader1 {
    color: white;
    font-size: 17px;
    line-height: normal;
  }
  .subHeader2 {
    color: #121212;
  }
  .subHeader3 {
    color: #121212;
    text-align: center;
    display: block !important;
    overflow-wrap: break-word;
  }

  .blackBackGround {
    padding-left: 12px !important;
  }
  /* .blackBackGround {
    background-color: black;
  }
  .header1 {
    font-size: 4em;
  } */
  .bannerImage {
    height: 55vw;
    margin-top: 5vw;
  }
  .header3 {
    color: #121212;
    font-size: 5vw;
  }
  .subHeader5 {
    margin-bottom: 4vw;
    font-size: 3vw;
    line-height: normal;
    color: #121212;
  }
  .subHeader4 {
    font-size: 3vw;
    color: #121212 !important;
  }
}
