.bannerContainer {
  /* background-color: #ebe3d1; */
  /* border-radius: 10px; */
  padding: 3em;
}
.header1 {
  color: #212121;
  font-family: Aileron;

  /* font-family: Desire-Pro; */
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -2px;
  display: flex;
  justify-content: center;
  margin-bottom: 0.7em !important;
}
.header2 {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin-top: 2em !important; */
  width: 70%;
  margin: auto !important;
}
.col1 {
  text-align: center;
}
.bannerImage {
  /* height: 500px; */
  width: 22vw;
}
.diffColor {
  color: #f4a537;
  font-family: Desire-Pro;
  margin-left: 10px;
}

.mapHeader {
  color: #212121;
  display: flex;
  gap: 20px;
  font-family: Aileron;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}
.mapBody {
  text-align: left;
  color: #212121;
  font-family: Aileron;
  font-size: 1.05vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 1.5em 0em;
}
.numberContainer {
  border-radius: 10px;
  border: 1px solid #f4a537;
  padding: 5px 10px;
  height: fit-content;
}
.margin {
  margin: auto 0;
}
.imgCenter {
  /* display: flex; */
  margin: auto !important;
}
.rightContent,
.minPadding {
  display: grid !important;
}
@media screen and (max-width: 769px) {
  .bannerImage {
    height: 300px;
  }
  .minPadding {
    padding: 3em !important;
  }
  .imgCenter {
    padding: 0em 3em !important;
  }
  .mapBody {
    font-size: 2vw;
  }
  .mapHeader {
    font-size: 2.5vw;
  }
}
@media screen and (max-width: 426px) {
  .bannerImage {
    width: 55vw;
  }
  .imgCenter {
    padding: 0em 2em !important;
  }
  .margin {
    font-size: 20px;
  }
  .mapBody {
    font-size: 17px;
  }
  .minPadding {
    padding: 2em !important;
  }
  .numberContainer {
    font-size: 4vw;
  }
}
