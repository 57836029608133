.header1 {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  text-align: start;
}
.mainContiner {
  padding-top: 3rem;
}
.card {
  border-radius: 20px;
  background: #fff;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 3rem 2rem !important;
  /* width: 75%; */
  margin: 0rem 0rem 1rem 0rem !important;
}
.defaultLogo {
  width: 100%;
  max-width: 150px;
  height: 150px;
  cursor: pointer;
}
.greyDot {
  margin-bottom: 1.8rem;
}
.cardName {
  color: #000;
  font-family: Aileron;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.cardEmail {
  color: #bdbdbd;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.cardLogout {
  color: #212121;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border: 0;
  background-color: #fff;
}
.bodyWrapper {
  display: flex;
  /* gap: 10px; */
  flex-direction: column;
}
.cursorPointer {
  cursor: pointer;
}
.changePassword {
  color: #212121;
  text-align: right;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  margin-top: 20px;
  cursor: pointer;
  width: fit-content;
  align-self: end;
}
.changePassword:hover {
  text-decoration-line: underline;
  color: #212121;
}

@media (max-width: 764px) {
  .mycontainer {
    margin: auto !important;
    max-width: 90% !important;
  }

  .mainContiner {
    padding-top: 1rem;
  }

  .header1 {
    font-size: 40px;
  }
}

@media (max-width: 1184px) {
  .mycontainer {
    margin: auto !important;
    max-width: 90% !important;
  }
}

.mobileContainer {
  margin-left: 20px;
  margin-right: 20px;
}

.mobileHeader1 {
  height: 32px;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;

  color: #212121;
  font-family: Desire-Pro;
  text-align: start;

  margin-top: 45px;
  margin-bottom: 32px;
}

.profileCard {
  margin-bottom: 50px;
}

.mobileCardLogout {
  color: #212121;
  font-family: Aileron;
  font-style: normal;
  font-weight: 600;
  border: 0;
  background-color: #fff;
  text-align: right;
  font-size: 14px;
  line-height: normal;
}
.mobileChangePassword {
  color: #212121;
  text-align: left;
  font-family: Aileron;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;

  font-size: 14px;
}
.profileFoot {
  margin-top: 60px;
  margin-bottom: 120px;
  display: flex;
  justify-content: space-between;
}
.noteText {
  display: flex;
  padding-left: 32px;
  align-items: center;
  gap: 10px;

  color: rgba(33, 33, 33, 0.20);
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 8px;
}
.labelText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}