.bannerContainer {
  padding: 3em 0em;
}
.header1 {
  color: #212121;

  font-family: Desire-Pro;
  font-size: 4em;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -2px;
}
.header2 {
  color: #212121;
  font-family: Desire-Pro;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 2em !important;
}
.col1 {
  text-align: left;
}
.bannerImage {
  height: 300px;
}
.bannerImage1 {
  max-height: 280px;
  max-width: 600px;
  height: 100%;
  width: 80%;
  margin: 2rem;
}
.banner1 {
  background-color: #ebe3d1;
  border-radius: 10px;
  height: 320px;
  margin: 10px 0em;
}
.banner2 {
  background-color: #1c2a4d;
  border-radius: 10px;
  color: #fff;
  text-align: left;
  /* font-family: Aileron; */
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1em;
  height: 320px;
  margin: 10px 0em;
}

.banner3 {
  background-image: url("../../../Assets/Images/about-banner-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  color: #fff;
  font-size: 2em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 1em;
  display: flex;
  text-align: center;
  align-items: center;
  height: 320px;
  margin: 10px 0em;
}

.banner4 {
  background-color: #ebe3d1;
  border-radius: 10px;
  color: #212121;
  text-align: left;
  /* font-family: Aileron; */
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1em;
  height: 320px;
  margin: 10px 0em;
}

.banner5 {
  background-image: url("../../../Assets/Images/about-banner-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  color: #fff;
  /* font-size: 2em; */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 1em;
  display: flex;
  text-align: center;
  align-items: center;
  height: 320px;
  margin: 10px 0em;
}
.banner6 {
  background-color: #1c2a4d;
  border-radius: 10px;
  color: #fff;
  text-align: left;
  /* font-family: Aileron; */
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  padding: 1em;
  height: 320px;
  margin: 10px 0em;
}
.banner7 {
  background-color: #1c2a4d;
  background-image: url("../../../Assets/Images/about-banner-7.svg");
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: center;
  border-radius: 10px;
  height: 320px;
  margin: 10px 0em;
  padding: 2em;
}

@media screen and (max-width: 426px) {
  .bannerContainer {
    padding: 0em 2em;
    gap: 2em;
  }
  .banner1 {
    background-color: transparent;
    height: 200px;
  }
  .banner2 {
    background-color: transparent;
    color: #212121;
    height: 100%;
  }
  .banner3 {
    height: 100%;
    font-size: 1.5em;
    min-height: 10em;
  }

  .banner4 {
    background-color: transparent;
    height: 200px;
  }
  .banner5 {
    height: 100%;
    font-size: 1.5em;
    min-height: 10em;
  }
  .banner6 {
    background-color: transparent;
    height: 200px;
    color: #212121;

    font-size: 22px;
    font-weight: 600;
  }
  .banner7 {
    display: none;
  }
}