.carouselContainer {
    border-radius: 1.25rem;
    background: #EBE3D1;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.15);
    height: 11.9375rem;
}

.sliderBtn {
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
    margin-right: 0.5rem;
    
}

.btnBorder{
    border: none;
    background-color: transparent;
}

.btnDisabled{
   opacity: 0.2;
}

.text1 {
    color: #898377;
    font-family: Aileron;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal
}

.text2 {
    color: #898377;
    font-family: Aileron;
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
