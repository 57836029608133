.bannerContainer {
  background-color: black;
  width: 100%;
  padding: 100px 170px 200px 170px;
  height: 100%;
  min-height: 100vh;
}
.mobileBannerContainer {
  padding: 20px 20px 20px 20px;
  background-color: black;
  min-height: 100%;
}
.headersContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.mobileHeaderTest {
  align-self: center;
  text-align: left;
  line-height: 1.1em;
}
.row {
  width: fit-content;
}
.header1 {
  /* width: fit-content !important; */
  color: #f3a537;
  /* text-align: left; */
  font-family: Desire-Pro;
  font-size: 5em;
  font-style: normal;
  font-weight: 400;
  /* line-height: 1em; */
  height: 100px;
  /* border-bottom: solid 4px; */
  /* min-width: 5rem; */
  /* letter-spacing: -1px; */
}
.mobileInputHeader {
  color: #f3a537;
  border-bottom: solid 2px;
  max-width: 65px !important;
  margin-left: 12px;
  padding: 0px !important;
}

.header5 {
  color: #f3a537;
  text-align: left;
  font-family: Desire-Pro;
  font-size: 5em;
  font-style: normal;
  font-weight: 400;
}
.mobileHeader5 {
  color: #f3a537;
  text-align: left;
  font-family: Desire-Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.header6 {
  color: #f3a537;
  margin: 0px 5px;
  font-family: Desire-Pro;
  font-size: 1em;
}
.mobileHeader6 {
  color: #f3a537;
  margin: 0px 5px;
  font-family: Desire-Pro;
  font-size: 1em;
}
.header2 {
  color: #fff;
  font-family: Desire-Pro;
  font-size: 5em;
  font-style: normal;
  font-weight: 400;
  /* line-height: 80px; */
  text-align: left;
  /* letter-spacing: -3px; */
  /* display: contents; */
}
.mobileHeader2 {
  color: #fff;
  font-family: Desire-Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  padding-right: 0px !important;
}
.header3 {
  color: #f3a537;
  /* text-align: left; */
  font-family: Desire-Pro;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.mobileHeader3 {
  color: #f3a537;
  /* text-align: left; */
  font-family: Desire-Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.header4 {
  color: #fff;
  font-family: Desire-Pro;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  display: contents;
}
.mobileHeader4 {
  color: #fff;
  font-family: Desire-Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: contents;
}
.headerUnit {
  color: #f4a537;
  /* text-align: center; */
  font-family: Desire-Pro;
  font-size: 100px;
  font-style: normal;
  font-weight: 400;
  line-height: 80px;
  display: inline;
}
.headerLine {
  color: #f4a537;
  font-family: Desire-Pro;
  font-size: 100px; /* Adjust as needed */
  font-style: normal;
  font-weight: 400;
  line-height: 80px;
  display: inline; /* Add this to keep the elements inline */
}
.inputBox {
  /* border-radius: 10px; */
  border: none;
  border-bottom: 0.05em solid #f3a537 ;
  background-color: transparent;
  /* border: 1px solid rgba(33, 33, 33, 0.4);
  background-color: black; */

  color: #f3a537;
  /* padding: 5px 12px; */
  width: 151px;
  height: 100%;
  /*font-style: normal; */
  font-weight: 400;
  /* line-height: 22px; */

  color: #f3a537;
  text-align: center;
  font-family: Desire-Pro;

  &::placeholder {
    color: #f3a537;
    opacity: 0.7;
  }

  &:focus {
    /* border: 0px; */
    outline: 0px;
  }
}
.mobileInputBox {
  width: 65px;
  border: none;
  background-color: transparent;
  color: #f3a537;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  font-family: Desire-Pro;

  &::placeholder {
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #00000040;
  }

  &:focus {
    border: 0px;
    outline: 0px;
  }
}
input[type="number"] {
  /* Remove up and down arrows from number input */
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  display: none !important;
}

.formLabel {
  color: #212121;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mobileFormLabel {
  color: #f3a537;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.colorGold {
  color: #f3a537;
}
.whiteDropdown {
  background-color: transparent;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding: 12px 20px;
  gap: 10px;
  flex-shrink: 0;
  margin-right: 20px;
  border-bottom: 1px solid #f3a537;
  color: #f3a537;
}
.radio1 {
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 103px;
  height: 50px;
  padding: 12px 20px;
  gap: 10px;
  flex-shrink: 0;
  margin-right: 20px;
}
.mobileRadio1 {
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: auto !important;
  height: 50px;
  padding: 12px 20px;
  gap: 10px;
  flex-shrink: 0;
  margin-right: 0px;
}
.radio2 {
  background-color: #fff;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  width: 190px;
  height: 50px;
  padding: 12px 20px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  margin-right: 20px;
}
.mobileRadio2 {
  background-color: unset !important;
  margin-right: 0px !important;
  width: auto !important;
}
.radio3 {
  background-color: #fff;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  width: 190px;
  height: 50px;
  padding: 12px 20px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  margin-right: 20px;
}
.radio4 {
  background-color: #fff;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  width: 190px;
  height: 50px;
  padding: 12px 20px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}
.mobileRadio4 {
  background-color: unset !important;
  margin-right: 0px !important;
  width: auto !important;
}
.radioInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #f4a537;
  border-radius: 50%;
  margin-right: 5px;
  position: relative;
}
.radioInput:checked::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #f4a537;
}

.rowForm {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.mobileRowForm {
  gap: 10px !important;
  margin: 0px !important;
  padding: 0px !important;
}

.parentRowForm {
  gap: 24px;
  display: flex;
  flex-direction: column;
}
.mobileParentRowForm {
  gap: 20px;
}

.select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #f4a537;
  border-radius: 10px;
  background-color: #ffffff;
  color: #000000;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23000000" width="24" height="24" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  width: 288px;
  height: 50px;
  flex-shrink: 0;
}
.select select::-ms-expand {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: none;
}
.select select:focus {
  outline: none;
}
.mobileSelect select {
  width: 100% !important;
}
.option {
  border-radius: 50px;
  background-color: #ebe3d1;
  color: #000000;
  height: 100px;
}

.totalInput {
  display: flex;
}
.input1 {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #f4a537; /* Change this to your desired color */
  border-radius: 10px;
  background-color: #ffffff; /* Change this to your desired background color */
  color: #000000; /* Change this to your desired text color */
  width: 313px;
  height: 50px;
  flex-shrink: 0;
}
.input:focus {
  outline: none;
  border-color: #f4a537; /* Change this to your desired focus color */
}
.mobileInput {
  width: 100% !important;
}

.submitButton {
  background-color: #f4a537; /* Change this to your desired background color */
  color: #000000; /* Change this to your desired text color */
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  width: 200px;
  height: 50px;
  flex-shrink: 0;
  display: block;
  align-self: center;
  /* margin: auto; */
  color: #212121;
  text-align: center;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.submitGreyButton {
  background-color: #f4a537; /* Change this to your desired background color */
  color: #000000; /* Change this to your desired text color */
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 10px;

  display: flex;
  width: 200px;
  height: 50px;
  flex-shrink: 0;
  display: block;
  align-self: center;
  margin: auto;
  color: #212121;
  text-align: center;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: not-allowed;
}
.mobileSubmitGreyButton {
  background-color: #f4a537; /* Change this to your desired background color */
  color: #000000; /* Change this to your desired text color */
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 10px;

  width: 200px;
  height: 50px;
  flex-shrink: 0;
  margin: auto;
  color: #212121;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  cursor: not-allowed;
}

.view {
  color: #fff;
  text-align: center;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 40px;
}
.view:hover {
  color: #f4a537;
}
.mobileView {
  text-align: left !important;
  margin: 0px !important;
}

.subHeader1 {
  text-align: left;
  color: #fff;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
}
.subHeader2 {
  text-align: left;
  color: #f4a537;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.subHeader3 {
  text-align: left;
  color: #fff;
  font-family: Aileron;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
}
.col1 {
  /* margin-left: 1em; */
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.bannerImage {
  width: 325px;
  height: 526px;
}
.mobileBannerImage {
  width: 126px;
  height: 204px;
}
.imageContainer {
  border-radius: 10px;
  background: #ebe3d1;
  /* background-image: url("../../../public/images/contact-us-phone.svg"); */
}

.ContactUsContainer {
  border-radius: 10px;
  background: #1c2a4d;
  padding: 2rem;
  /* margin: 0px 2rem; */
}
.error {
  color: #f4a537;
  font-family: Desire-Pro;
  font-size: 4em;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  text-align: left;
}

.mobileHeader1 {
  padding-left: 20px;
  padding-right: 20px;
}
@media screen and (max-width: 769px) {
  .imageContainer {
    margin: 0em 3em;
  }
  .minPadding {
    margin: 0px !important;
  }

  .blackBackGround {
    background-color: black;
  }
  .header1 {
    font-size: 4em;
  }

  .bannerContainer {
    padding: 100px 10px 200px 10px;
  }

  .colMethod {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
.paymentMethod {
  display: flex;
}
.mobileSubmitBtn {
  margin-bottom: 32px;
  text-align: left;
}
