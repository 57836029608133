.investmentContainer {
    border-radius: 1.25rem;
    background: #FFF;
    display: flex;
    padding: 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.15);
}

.investmentHeadingContainer {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    flex: 1 0 0;
}

.investSelect {
    border: 0px;
    background: transparent;
    color: #F4A537;
    text-align: right;
    font-family: Aileron;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.dropdownText {
    color: #F4A537;
    text-align: left;
    font-family: Aileron;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.investmentHeading {
    color: #F4A537;
    font-family: Aileron;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.orderEllipse {
    width: 1.0625rem;
    /* fill: #F3A537; */
}

.headerbtn {
    width: 2rem;
    width: 2rem;
    margin-left: 0.5rem;
}
@media screen and (max-width: 767px) {
    .investmentContainer {
        border-radius: 0;
        border-top: 1px solid #aaa7a7;
        border-bottom: 1px solid #aaa7a7;
    }
}