.PlainBtn {
  width: auto;
  height: 50px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 2px solid #1d3118;
  background: #fff;
  text-align: center;
  padding: 5px 3px;
  color: #1d3118;
  font-family: Aileron;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  min-width: 115px;
  cursor: pointer;
}

.PlainBtn:hover {
  background: #1d3118;
  color: #fff;
}

.ThemeBlackBackground {
  background: #1d3118 !important;
  color: #fff !important;
}

.ThemeBlackBackground:hover {
  background: #2e3f2a !important;
}

.ThemeOrange {
  background: #1d3118;
  color: #ebe3d1;
  border: 2px solid #f4a537;
}

.ThemeOrange:hover {
  background: #fff;
  color: #1d3118;
}

.ThemeOrangeBackground {
  background: #f4a537;
  color: #000;
  border: transparent;
}

.ThemeOrangeBackground:hover {
  background: #da993e;
  color: #000;
}

.ThemeWhiteBorder {
  border: 2px solid #fff;
  background: #1d3118;
  color: #fff;
}

.ThemeWhiteBorder:hover {
  color: #1d3118;
  background: #fff !important;
}

@media (max-width: 764px) {
  .PlainBtn {
    padding: 2;
    min-width: 50px;
    width: 100%;
    height: 40px;

    font-family: Aileron;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
  }
}

@media (max-width: 1184px) {
  .PlainBtn {
    padding: 2;
    min-width: 50px;
    /* width: 100%; */
    height: 40px;

    font-family: Aileron;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
  }
}
